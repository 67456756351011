import Word from "@/components/atoms/Word";
import { urlForImage } from "@/sanity/lib/image";
import { FaRegCheckCircle } from "react-icons/fa";
export function removeDashes(str: string) {
  return str.replace(/-/g, " ");
}
// export const backgroundHandler = (value: string) => {
//   if (value === "dark") {
//     return "bg-black text-white";
//   }
//   if (value === "light") {
//     return "bg-[#f5f5f6] text-black";
//   }
//   return "bg-[#f5f5f6]";
// };

export const sectionBackgroundHandler = (background: any) => {
  if (background === "light") return "bg-[#F5F5F6]";
  if (background === "dark") return "bg-[#0C1E20]";
  if (background === "darkLight") return "bg-darkLight";
  if (background === "lightDark") return "bg-lightDark";
  return "bg-[#F5F5F6]";
};
export const sectionSpacingHandler = (spacing: any) => {
  if (spacing === "standard") return "py-[45px]";
  if (spacing === "small") return "py-[30px]";
  if (spacing === "large") return "py-[80px]";
  return "py-[45px]";
};
export const shouldShow = (property: any, values: any) => {
  if (!Array.isArray(values)) {
    values = [values];
  }
  property = String(property);
  return values.includes(property);
};
export const getDefaultSEOValue = (
  value: string | undefined,
  defaultValue: string
) => value?.trim() || defaultValue;

export const portableTextComponents = {
  types: {
    image: ({ value }: any) => {
      return (
        <div className="max-w-[1128px] py-10 mx-auto ">
          <img
            src={urlForImage(value).url() || ""}
            alt={value.alt}
            className="w-full rounded-[32px]"
          />
        </div>
      );
    },
  },
  block: {
    normal: ({ children }: any) => (
      <p className="max-w-[896px] pb-6 mx-auto font-medium text-base leading-[150%]">
        {children}
      </p>
    ),
    h1: ({ children }: any) => (
      <h1 className="text-[3rem] phone:text-h1 font-general-sans font-medium leading-[120%]">
        {children}
      </h1>
    ),
    h2: ({ children }: any) => (
      <h2 className="text-h1  leading-[120%]">{children}</h2>
    ),
    h3: ({ children }: any) => (
      <h3 className="text-[26px] sm:text-[38px] md:text-h3 font-general-sans leading-[120%]">
        {children}
      </h3>
    ),
    h4: ({ children }: any) => (
      <h4 className="max-w-[896px] text-[27px]  md:text-[35px] pb-6 mx-auto font-semibold  ">
        {children}
      </h4>
    ),
    h5: ({ children }: any) => (
      <h5 className="max-w-[896px] py-10 mx-auto font-semibold text-[27px] leading-[120%]">
        {children}
      </h5>
    ),
    h6: ({ children }: any) => (
      <h6 className="text-[1.25rem] phone:text-h6 font-general-sans font-medium leading-[120%]">
        {children}
      </h6>
    ),
    tag: ({ children }: any) => (
      <p className="text-tag font-general-sans font-medium leading-[120%]">
        {children}
      </p>
    ),
    subtitle: ({ children }: any) => (
      <p className="text-[18px] md:text-subtitle leading-[160%] font-semibold py-4">
        {children}
      </p>
    ),
    body: ({ children }: any) => (
      <p className="text-[0.938rem] phone:text-body font-general-sans-variable leading-[140%] py-2">
        {children}
      </p>
    ),
    bodyL: ({ children }: any) => (
      <p className="text-[1rem] phone:text-bodyL font-general-sans-variable leading-[140%] py-2">
        {children}
      </p>
    ),
    caption: ({ children }: any) => (
      <p className="text-[0.75rem] phone:text-caption font-general-sans font-medium leading-[140%] py-2">
        {children}
      </p>
    ),
    blockquote: ({ children }: any) => (
      <p className="max-w-[896px] py-10 mx-auto font-semibold text-4xl leading-[120%]">
        {children}
      </p>
    ),
  },
  list: {
    bullet: ({ children }: any) => (
      <ul className="flex flex-col py-2 md:py-8 gap-4 leading-[150%]">
        {children.map((child: any, index: any) => {
          return (
            <li key={index} className="flex font-semibold gap-4 items-center">
              <div>
                <FaRegCheckCircle size={20} />
              </div>
              {child}{" "}
            </li>
          );
        })}
      </ul>
    ),
    number: ({ children }: any) => (
      <ol className="max-w-[896px] py-10 mx-auto font-medium text-base leading-[150%]">
        {children}
      </ol>
    ),
  },
  marks: {
    link: ({ children, value }: any) => {
      // const rel = !value.href.startsWith("/")
      //   ? "noreferrer noopener"
      //   : undefined;
      return <a href={value.href}>{children}</a>;
    },
  },
};
export const portableTextComponentsAnimated = {
  types: {
    image: ({ value }: any) => {
      return (
        <div className="max-w-[1128px] py-10 mx-auto ">
          <img
            src={urlForImage(value).url() || ""}
            alt={value.alt}
            className="w-full rounded-[32px]"
          />
        </div>
      );
    },
  },
  block: {
    normal: ({ children }: any) => (
      <p className="max-w-[896px] pb-6 mx-auto font-medium text-base leading-[150%]">
        {children}
      </p>
    ),
    h1: ({ children }: any) => (
      <h1 className="text-[3rem] phone:text-h1 font-general-sans font-medium leading-[120%]">
        {children}
      </h1>
    ),
    h2: ({ children }: any) => (
      <h2 className="text-h1  leading-[120%]">{children}</h2>
    ),
    h3: ({ children }: any) => (
      <h3 className="text-[26px] sm:text-[38px] md:text-h3 font-general-sans leading-[120%]">
        {children}
      </h3>
    ),
    h4: ({ children }: any) => {
      return (
        <h4 className="text-[26px] sm:text-[36px] md:text-h4 rounded-tl-none py-4 rounded-3xl bg-[#0C1E20] inline-block font-semibold text-white leading-tight ">
          <Word isDark text={children[0]} />
        </h4>
      );
    },

    h5: ({ children }: any) => (
      <h5 className="max-w-[896px] py-10 mx-auto font-semibold text-[27px] leading-[120%]">
        {children}
      </h5>
    ),
    h6: ({ children }: any) => (
      <h6 className="text-[1.25rem] phone:text-h6 font-general-sans font-medium leading-[120%]">
        {children}
      </h6>
    ),
    tag: ({ children }: any) => (
      <p className="text-tag font-general-sans font-medium leading-[120%]">
        {children}
      </p>
    ),
    subtitle: ({ children }: any) => (
      <p className="text-[18px] md:text-subtitle leading-[160%] font-semibold py-4">
        {children}
      </p>
    ),
    body: ({ children }: any) => (
      <p className="text-[0.938rem] phone:text-body font-general-sans-variable leading-[140%] py-2">
        {children}
      </p>
    ),
    bodyL: ({ children }: any) => (
      <p className="text-[1rem] phone:text-bodyL font-general-sans-variable leading-[140%] py-2">
        {children}
      </p>
    ),
    caption: ({ children }: any) => (
      <p className="text-[0.75rem] phone:text-caption font-general-sans font-medium leading-[140%] py-2">
        {children}
      </p>
    ),
    blockquote: ({ children }: any) => (
      <p className="max-w-[896px] py-10 mx-auto font-semibold text-4xl leading-[120%]">
        {children}
      </p>
    ),
  },
  list: {
    bullet: ({ children }: any) => (
      <ul className="flex flex-col py-2 md:py-8 gap-4 leading-[150%]">
        {children.map((child: any, index: any) => {
          return (
            <li key={index} className="flex font-semibold gap-4 items-center">
              <div>
                <FaRegCheckCircle size={20} />
              </div>
              {child}{" "}
            </li>
          );
        })}
      </ul>
    ),
    number: ({ children }: any) => (
      <ol className="max-w-[896px] py-10 mx-auto font-medium text-base leading-[150%]">
        {children}
      </ol>
    ),
  },
  marks: {
    link: ({ children, value }: any) => {
      // const rel = !value.href.startsWith("/")
      //   ? "noreferrer noopener"
      //   : undefined;
      return <a href={value.href}>{children}</a>;
    },
  },
};
export const shouldShowCustomRule = (
  currentValue: any,
  property: any,
  values: any
) => {
  if (shouldShow(property, values) && currentValue === undefined)
    return `Validation Error: Please fill out this required field.`;
  return true;
};
export const cn = (value: any, truthyClass: any, falsyClass: any) => {
  return value ? truthyClass : falsyClass;
};
export const removedCategory = [
  "Products supporting Greenfleet",
  "Global GreenTag certified products",
  "Products supporting ILF",
  "GECA certified products",
];

export const learnCategory = [
  { title: "Cleaning Guides", value: "cleaning-guides" },
  { title: "Sustainability Tips", value: "Sustainability-tips" },
  { title: "ESG Insights", value: "esg-insights" },
  { title: "Positive Change", value: "positive-change" },
];
export function formatDate(inputDate: any) {
  const options: any = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = new Date(inputDate).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
}
export const getTitleByCategory = (categoryValue: string) => {
  const category = learnCategory.find((cat) => cat.value === categoryValue);
  return category ? category.title : categoryValue;
};
export function cn1(...args: any) {
  return args.filter(Boolean).join(" ");
}
export function createHrefLink(item: any) {
  let hrefLink = "";

  if (item.referenceUrl._type === "product") {
    hrefLink = `/brands/${item.referenceUrl.brand}/${item.referenceUrl.slug}`;
  } else if (item.referenceUrl._type === "sector") {
    hrefLink = `/sector/${item.referenceUrl.slug}`;
  } else {
    hrefLink = `/${item.referenceUrl.slug}`;
  }

  return hrefLink;
}
export async function sendData(url: any, data: any) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ": " + pair[1]);
  // }

  const response = await fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: formData,
  });
  return response;
}
